<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>Disposisi Terkirim</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr />

        <div style="width: 800px; margin: 0 auto">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-md-12">
                <form method="post" @submit.prevent="changeDt">
                  <div class="form-body">
                    <div class="row justify-content-md-center">
                      <div class="col-md-9 col-sm-12">
                        <div class="row">
                          <div class="form-group" style="width: 300px">
                            <input
                              placeholder="Pencarian Nomor Surat"
                              type="text"
                              class="form-control"
                              v-model="nomor_surat"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-md-center">
                      <div class="col-md-9 col-sm-12">
                        <div class="row">
                          <div class="form-group" style="width: 300px">
                            <input
                              placeholder="Pencarian Nomor Kendali"
                              type="text"
                              class="form-control"
                              v-model="nomor_kendali"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-md-center">
                      <div class="col-md-9 col-sm-12">
                        <div class="row">
                          <div class="form-group" style="width: 300px">
                            <input
                              placeholder="Pencarian Isi Ringkas"
                              type="text"
                              class="form-control"
                              v-model="ringkasan"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    <div class="row justify-content-md-center">
                      <div class="col-md-7 pl-5">
                        <button
                          type="submit"
                          class="btn btn-success"
                          title="Cari"
                        >
                          Cari</button
                        >&nbsp;
                        <button
                          type="button"
                          class="btn btn-inverse"
                          title="Reset"
                          @click="resetInputs()"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onDetailTL="doDetailTL"
              :onDetailTLI="doDetailTLI"
              :forbidDetail="forbidRead"
              :forbidDetailTL="forbidRead"
              :forbidDelete="true"
              :overideSearch="search"
              @onSearch="onSearch"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "../../../components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");
    let params = {};
    if (last[0].includes("disposition_send")) {
      params = JSON.parse(last[1]);
    }
    return {
      search: params.search || "",
      headers: {
        nomor_surat: {
          label: "Nomor Surat",
          sortable: true,
        },
        no_opd: {
          label: "Nomor Kendali",
          sortable: true,
        },
        catatan_disposisi: {
          label: "Catatan",
          sortable: true,
        },
        description: {
          label: "Isi Ringkas",
          sortable: true,
        },
        penerima: {
          label: "Tujuan Disposisi",
          sortable: false,
        },
        tanggal_dibuat: {
          label: "Tanggal Disposisi Dibuat",
          sortable: true,
          type: "dateTime",
        },
        tanggal_selesai: {
          label: "Tanggal Penyelesaian",
          sortable: true,
          type: "dateTime",
        },
        is_status: {
          label: "Status",
          sortable: false,
        },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.dispositionSend.items !== undefined) {
        if (this.$store.state.dispositionSend.items.items !== undefined) {
          if (
            !Array.isArray(this.$store.state.dispositionSend.items.items) &&
            this.$store.state.dispositionSend.items.items !== undefined
          ) {
            data = Object.keys(
              this.$store.state.dispositionSend.items.items
            ).map((i) => this.$store.state.dispositionSend.items.items[i]);
          } else {
            data = this.$store.state.dispositionSend.items.items;
          }
        }
      }
      let newData = [];
      for (let index = 0; index < data.length; index++) {
        let element = data[index];
        const send_dispo = "";
        if (element.send_recipient != "") {
          send_dispo = element.send_recipient;
        } else if (element.send_personal) {
          send_dispo = element.send_personal;
        } else {
          send_dispo = "";
        }

        newData[index] = {
          id: element.id,
          agenda_number: element.agenda_number,
          status: element.is_read == 1 ? "Sudah dibaca" : "Belum dibaca",
          is_cancel:
            element.is_false == 1
              ? "Salah Kirim"
              : element.is_cancel == 0
              ? "Aktif"
              : "Dibatalkan",
          is_status: element.is_status,
          nomor_surat: element.nomorsurat,
          subject: element.subject,
          aksi: element.aksi,
          sifat: element.sifat,
          catatan: element.catatan,
          no_opd: element.no_opd,
          isLaporan: element.isLaporan,
          description: element.description,
          memproses: element.memproses2,
          tanggal_diterima: element.tanggal_diterima,
          tanggal_dibuat: element.tanggal_dispo,
          tanggal_selesai: element.tanggal_selesai,
          catatan_disposisi: element.catatan_disposisi,
          // tanggal_dibuat: element.tanggal_dibuat,
          redaksi: element.redaksi,
          asal_surat: element.document_asrat,
          penerima: element.penerima,
          btl: element.btl,
          responsedispo: element.responsedispo,
          send_dispo: send_dispo,
        };
      }
      return newData;
    },
    filters() {
      let filt = {};
      if (this.$store.state.dispositionSend.items !== undefined) {
        if (this.$store.state.dispositionSend.items.filters !== undefined) {
          filt = this.$store.state.dispositionSend.items.filters;
        }
      }
      return filt;
    },
    state() {
      return this.$store.state.dispositionSend;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.delete;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      search: this.search,
      items: [],
    };
    this.$store.commit("dispositionSend/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    get(val) {
      this.$store.dispatch("dispositionSend/getDispositionSend", val);
    },
    doDetail(val) {
      this.$store.dispatch("dispositionSend/onDetail", val);
    },
    doDetailTL(val) {
      this.$store.dispatch("dispositionSend/onDetailTL", val);
    },
    doDetailTLI(val) {
      this.$store.dispatch("dispositionSend/onDetailTLI", val);
    },
    doEdit(val) {
      this.$store.dispatch("documentIn/onDisposisi", val);
    },
    doDelete(val) {
      this.$store.dispatch("dispositionSend/submitDelete", val);
    },
    changeDt() {
      const state = {
        loaded: false,
        items: [],
        form: this.form,
        search: this.search,
        nomor_surat: this.nomor_surat,
        nomor_kendali: this.nomor_kendali,
        ringkasan: this.ringkasan,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("dispositionSend/STATE", state);
      this.get(state);
    },
    resetInputs() {
      this.nomor_surat = "";
      this.nomor_kendali = "";
      this.ringkasan = "";
      const state = {
        loaded: false,
        items: [],
        form: this.form,
        search: this.search,
        nomor_surat: this.nomor_surat,
        nomor_kendali: this.nomor_kendali,
        ringkasan: this.ringkasan,
      };
      this.$store.commit("dispositionSend/STATE", state);
      this.get(state);
    },
  },
};
</script>
